import { Box } from "@mui/material";
import "./loading.scss";
import { ReactComponent as Loading } from "src/assets/images/loading.svg";

export default function LoadingSplash() {
  return (
    <Box className="loading-splash">
      <div className="loader-wrapper">
        <div className="loader">
          <Loading />
        </div>
      </div>
    </Box>
  );
}
