import { Box, Link, useMediaQuery, Drawer, SvgIcon } from "@mui/material";
import { Web3Button } from "@web3modal/react";

import "./header.scss";
import { NavLink } from "react-router-dom";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
import Logo from "../../assets/images/logo.svg";
import LogoM from "../../assets/images/logo-m.svg";
import { ConnectBtnBox } from "../Button/ConnectBtnBox";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export default function Header() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");

  return (
    <div className="header-view">
      <Box display="flex" alignItems="center" justifyContent="space-between" className="header">
        <Box display="flex" alignItems="center">
          <Link component={NavLink} to="/" className="logo">
            <img src={Logo} />
          </Link>
        </Box>
        <ConnectButton.Custom>
          {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
            // Note: If your app doesn't use authentication, you
            // can remove all 'authenticationStatus' checks
            const ready = mounted && authenticationStatus !== "loading";
            const connected =
              ready && account && chain && (!authenticationStatus || authenticationStatus === "authenticated");

            return (
              <div
                {...(!ready && {
                  "aria-hidden": true,
                  style: {
                    opacity: 0,
                    pointerEvents: "none",
                    userSelect: "none",
                  },
                })}
              >
                {(() => {
                  if (!connected) {
                    return <ConnectBtnBox onClick={openConnectModal}>CONNECT WALLET</ConnectBtnBox>;
                  }

                  if (chain.unsupported) {
                    return <ConnectBtnBox onClick={openChainModal}>Wrong network</ConnectBtnBox>;
                  }

                  return (
                    <div style={{ display: "flex", gap: 12 }}>
                      {!isVerySmallScreen && (
                        <ConnectBtnBox onClick={openChainModal} style={{ display: "flex", alignItems: "center" }}>
                          {chain.hasIcon && (
                            <div
                              style={{
                                background: chain.iconBackground,
                                width: 24,
                                height: 24,
                                borderRadius: 999,
                                overflow: "hidden",
                                marginRight: 4,
                              }}
                            >
                              {chain.iconUrl && (
                                <img
                                  alt={chain.name ?? "Chain icon"}
                                  src={chain.iconUrl}
                                  style={{ width: 24, height: 24 }}
                                />
                              )}
                            </div>
                          )}
                          <div className="flex-1">{chain.name}</div>
                        </ConnectBtnBox>
                      )}

                      <ConnectBtnBox
                        onClick={openAccountModal}
                        type="button"
                        style={{ whiteSpace: "nowrap", padding: "0 24px" }}
                      >
                        {account.displayName}
                        {account.displayBalance ? ` (${account.displayBalance})` : ""}
                      </ConnectBtnBox>
                    </div>
                  );
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
        {/* <Web3Button /> */}
      </Box>
    </div>
  );
}
