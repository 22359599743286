interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  280: {
    IDO: "0x749051447085cfcF4B253e95A6e532588a49b560",
    esPIKO: "0x5680386A5435C647112937643eBCA71791741143",
    WETH: "0x33F1FBe337A19BebCa41a3DCbA896752729286Ea",
  },
  324: {
    IDO: "0x29618c8eA82c631AC1E3Bea7499De6c4274b1ff7",
    esPIKO: "0xCaa5ba70830f8557fd6516Cf632b08AA4B6A528F",
    WETH: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
  },
};
