import { createMulticall, ListenerOptions } from "@uniswap/redux-multicall";
import useBlockNumber from "../../lib/hooks/useBlockNumber";
import { useEffect, useMemo } from "react";
import { batch, useDispatch } from "react-redux";
import useDebounce from "../../utils/useDebounce";
import { useChainId, useInterfaceMulticall } from "../../hooks/useContract";
import { SupportedChainId } from "../../constants/chains";

const multicall = createMulticall();

export default multicall;

export function getBlocksPerFetchForChainId(chainId: number | undefined): number {
  switch (chainId) {
    // case SupportedChainId.ARBITRUM_ONE:
    // case SupportedChainId.ARBITRUM_GOERLI:
    //   return 75;
    // case SupportedChainId.BSC_MAINNET:
    // case SupportedChainId.BST_TESTNET:
    case SupportedChainId.ZK_MAINNET:
    case SupportedChainId.ZK_TESTNET:
      return 2;
    default:
      return 5;
  }
}

export function MulticallUpdater() {
  const chainId = useChainId();
  const latestBlockNumber = useBlockNumber();
  const contract = useInterfaceMulticall();
  const dispatch = useDispatch();

  const handleDebounce = useDebounce(() => {
    batch(() => {});
  }, 500);
  useEffect(() => {
    handleDebounce();
    return () => {
      handleDebounce.cancel();
    };
  }, [dispatch, handleDebounce]);

  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(chainId),
    }),
    [chainId],
  );

  return (
    <multicall.Updater
      chainId={chainId}
      latestBlockNumber={latestBlockNumber}
      contract={contract}
      listenerOptions={listenerOptions}
    />
  );
}
