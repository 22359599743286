import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const ConnectBtnBox = styled(Button)({
  height: "56px",
  maxHeight: "56px",
  width: "100%",
  color: "#14D1B2",
  fontSize: "14px",
  fontFamily: "Gilroy-Bold",
  textTransform: "uppercase",
  background: "rgba(20, 209, 178, 0.2);",
  border: "none",
  borderRadius: "4px",
  padding: "0 12px",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)",
    color: "rgba(255, 255, 255, 0.54)",
  },
});
